import './skin.css';
import 'videojs-contrib-ads/dist/videojs.ads.css';
import 'videojs-ima/dist/videojs.ima.css';

import {
  CallToActionButtonPauseScreen,
  CustomCodePauseScreen,
  CustomizablePauseScreen,
  EndCardType,
  ExternalContentSlider,
  NewCustomizablePauseScreen,
  PromoCodeBox,
  useVideoJSPauseScreen,
  useVideoJSPlayerContext,
  useVideoJSThumbnailOverlay,
  VideoItemMoment,
  VideoItemType,
  VideoJSPlayer,
  VideoJSPlayerTitle,
  VideoJSThumbnailOverlay,
  VideoSlider,
  VideoSocialIcons,
} from '@entertainment-ai/react-component-library';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { CLIENT_CLASSNAMES } from '@/constants';
import { useGoogleAnalytics, useGoogleAnalyticsEx2 } from '@/features/analytics';
import { CarouselMoment, NormalizedVideoItem } from '@/features/normalize-data';
import { useAddView } from '@/features/player-widget/useAddView';
import { WidgetConfiguration } from '@/features/widget-preset';
import { Nullable } from '@/types';

import { useVideoAnalytics } from '../lightbox-player/analytics/useVideoAnalytics';

interface Props {
  videoItem: NormalizedVideoItem;
  momentIdForAutoplay?: string;
  configuration: WidgetConfiguration;
  autoplay?: boolean;
  hasPortraitMode?: boolean;
  style?: React.CSSProperties;
}

export function PlayerContainer({
  videoItem,
  momentIdForAutoplay,
  configuration,
  autoplay,
  hasPortraitMode,
  style,
}: Props) {
  const {
    id,
    type,
    videoFileUrl,
    captions,
    thumbnailUrl,
    title,
    moments,
    ctaTimestamps,
    monetization,
  } = videoItem;
  const {
    seekTo,
    setIsPlaying,
    isPlaying,
    isEnded,
    duration,
    playedFraction,
    currentTime,
  } = useVideoJSPlayerContext();
  const [selectedMoment, setSelectedMoment] = useState<CarouselMoment>();
  const [isLastMoment, setIsLastMoment] = useState(false);
  const [showEndCard, setShowEndCard] = useState(false);
  const { endCard: configuredEndcard } = selectedMoment || configuration;
  const [playerKey, setPlayerKey] = useState<number>(0);

  const trackViews = useGoogleAnalytics('views');
  const trackPauseScreen = useGoogleAnalytics('pauseScreen');
  const trackPlayerControls = useGoogleAnalytics('playerControls');
  const trackVideoCTAs = useGoogleAnalyticsEx2('videoCTAs');

  const pauseScreenHandlers = useVideoJSPauseScreen(
    selectedMoment?.id ? selectedMoment.id : moments[0]?.id,
    moments,
  );
  const { isOverlayVisible } = useVideoJSThumbnailOverlay();

  const endCard = configuredEndcard || videoItem.endCard;
  // in case upper row is showing wrong results
  // const endCard = videoItem.endCard || configuredEndcard;

  const callToActionButtonData: CallToActionButtonPauseScreen | undefined =
    endCard && endCard.callToActionButton?.label && endCard.callToActionButton.icon
      ? {
          iconName: endCard.callToActionButton.icon,
          title: endCard.callToActionButton.label,
          actionUrl: endCard.callToActionButton.url,
        }
      : undefined;

  const promoCodeData: PromoCodeBox | undefined =
    endCard && endCard.promoCodeButton?.label && endCard.promoCodeButton.text
      ? {
          code: endCard.promoCodeButton.label,
          text: endCard.promoCodeButton.text,
          onCopy: () => {},
        }
      : undefined;

  const isMoment = type === VideoItemType.Moment;
  const currentMoment = useMemo<VideoItemMoment | undefined>(() => {
    if (isMoment) {
      return normalizeVideoItemMoment(moments[0], videoItem, videoFileUrl);
    }

    return momentIdForAutoplay
      ? normalizeVideoItemMoment(
          moments.find((moment) => moment.id === momentIdForAutoplay),
          videoItem,
          videoFileUrl,
        )
      : undefined;
  }, [videoItem]);

  const { playedPercentage, onVideoEnd, onEndcardShow, onVideoStart } = useVideoAnalytics(
    videoItem,
  );

  const showLogo = videoItem?.orgLogoEnabled && !!videoItem?.orgLogoUrl;

  const videoMonetization = monetization || videoItem?.video?.monetization;

  const socialIcons = videoItem.video.shareSocialMediaIcons;

  useEffect(() => {
    playedPercentage({ played: playedFraction, playedSeconds: currentTime });
  }, [playedFraction, currentTime]);

  useEffect(() => {
    if (pauseScreenHandlers.isVisible) {
      onVideoEnd();
      onEndcardShow();
    } else {
      onVideoStart();
    }
  }, [pauseScreenHandlers.isVisible]);

  const addView = useAddView();
  const onViewCount = useCallback(() => {
    addView(id, isMoment);
  }, [addView, id, isMoment]);

  useEffect(() => {
    setShowEndCard(false);
    if (selectedMoment) {
      seekTo(selectedMoment.startTimestamp);

      const currentMomentIndex = moments.findIndex((moment) => moment.id === selectedMoment.id);
      setIsLastMoment(!moments[currentMomentIndex + 1]);
    }
  }, [selectedMoment]);

  useEffect(() => {
    if (pauseScreenHandlers.isVisible) setShowEndCard(true);
  }, [pauseScreenHandlers.isVisible]);

  const nextMomentHandler = () => {
    const currentMomentIndex = moments.findIndex((moment) => moment.id === selectedMoment?.id);
    if (!isLastMoment) {
      setSelectedMoment(moments[currentMomentIndex + 1]);
    }
  };

  const handleMomentClick = (momentId: string) => {
    setSelectedMoment(moments.find((moment) => moment.id === momentId));
  };

  useEffect(() => {
    setPlayerKey((prevKey) => prevKey + 1);
  }, [selectedMoment]);

  const promotedObjects = useMemo(
    () => videoItem.video.objects.filter((object) => object.promote),
    [videoItem.id],
  );
  const { promotedContentSettings } = videoItem.video;

  const isControlsDisabled = pauseScreenHandlers?.isVisible || isOverlayVisible;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (momentIdForAutoplay && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [momentIdForAutoplay]);

  const { isMonetizationEnabled, orgMonetization } = videoMonetization || {};

  const parseCustomField = (field: any) =>
    endCard?.cardType === 'CUSTOM' ? JSON.parse(field) : undefined;

  const newEndcardObj = {
    background: parseCustomField(endCard?.background),
    overlay: parseCustomField(endCard?.overlay),
    border: parseCustomField(endCard?.border),
    headline: parseCustomField(endCard?.headline),
    subline: parseCustomField(endCard?.subline),
    videoControlButtons: parseCustomField(endCard?.videoControlButtons),
    backgroundImageSettings: parseCustomField(endCard?.backgroundImageSettings),
  };
  const convertButtonDataFromServer = (fields: any) => {
    if (!fields) return;
    return Object.entries(fields).reduce((acc, [key, value]) => {
      if (['background', 'border', 'icon', 'text'].includes(key)) {
        // @ts-ignore
        return { ...acc, ...JSON.parse(value) };
        /* eslint-disable no-else-return */
      } else {
        return { ...acc, [key]: value };
      }
    }, {}) as any;
  };

  const renderNewCustomizablePauseScreen = (
    <NewCustomizablePauseScreen
      background={newEndcardObj.background}
      overlay={newEndcardObj.overlay}
      border={newEndcardObj.border}
      headline={newEndcardObj.headline}
      subline={newEndcardObj.subline}
      videoControlButtons={newEndcardObj.videoControlButtons}
      backgroundImageSettings={newEndcardObj.backgroundImageSettings}
      cardSize={endCard?.cardSize || 'fullscreen'}
      backgroundImageUrl={endCard?.backgroundImageUrl || ''}
      ctaButton1={convertButtonDataFromServer(endCard?.ctaButton1) || null}
      ctaButton2={convertButtonDataFromServer(endCard?.ctaButton2) || null}
      promoCodeButton={endCard?.promoCodeButton || null}
      title={title}
      objects={[]}
      showShareMoment={false}
      showWatchFullVideo={!!selectedMoment}
      showWatchNextMomentButton={!!selectedMoment && !isLastMoment}
      callToActionButton={callToActionButtonData}
      analyticsHandler={trackPauseScreen}
      socialMediaButtons={endCard?.socialMediaButtons || undefined}
      nextMomentHandler={nextMomentHandler}
      justifyContent="center"
      customActionWatchFullVideo={() => {
        setSelectedMoment(undefined);
        seekTo(0);
        setIsPlaying(true);
      }}
      // @ts-ignore
      cardOrientation={endCard?.cardOrientation}
      {...pauseScreenHandlers}
    />
  );

  const renderCustomizablePauseScreen = (
    <CustomizablePauseScreen
      title={title}
      objects={[]}
      showShareMoment={false}
      showWatchFullVideo={!!selectedMoment}
      promoCode={promoCodeData}
      callToActionButton={callToActionButtonData}
      analyticsHandler={trackPauseScreen}
      justifyContent="center"
      showWatchNextMomentButton={!!selectedMoment && !isLastMoment} // we only show one video/moment at a time
      socialMediaButtons={endCard?.socialMediaButtons || undefined}
      nextMomentHandler={nextMomentHandler}
      customActionWatchFullVideo={() => {
        setSelectedMoment(undefined);
        seekTo(0);
        setIsPlaying(true);
      }}
      {...pauseScreenHandlers}
    />
  );

  const renderCustomCodePauseScreen = (
    <CustomCodePauseScreen
      isVisible={pauseScreenHandlers.isVisible}
      htmlContent={endCard?.customCode || ''}
    />
  );

  const renderNewPauseScreen =
    endCard?.origin === EndCardType.CUSTOM_JS
      ? renderCustomCodePauseScreen
      : renderNewCustomizablePauseScreen;

  const renderEndCard =
    endCard?.cardType === 'CUSTOM' ? renderNewPauseScreen : renderCustomizablePauseScreen;

  const videoItemAspectRation =
    videoItem?.type === VideoItemType.Moment ? videoItem.video.aspectRatio : videoItem?.aspectRatio;

  const videoThumbnailUrl = thumbnailUrl ?? undefined;

  return (
    <div ref={ref}>
      {configuration.showThumbnailsSlider &&
      !configuration.showMomentsBelowVideo &&
      videoItem.type === VideoItemType.Video &&
      moments.length > 0 ? (
        <VideoSlider
          items={moments}
          activeItems={selectedMoment ? [selectedMoment.id] : []}
          onSlideClick={handleMomentClick}
          stickyNavigationArrows
        />
      ) : null}
      <VideoJSPlayer
        key={playerKey}
        adTagUrl={
          isMonetizationEnabled && orgMonetization?.monetizationUrl
            ? orgMonetization.monetizationUrl
            : undefined
        }
        src={videoFileUrl}
        captions={captions}
        // @ts-expect-error
        currentMoment={currentMoment || (selectedMoment as VideoItemMoment)}
        // @ts-ignore
        moments={selectedMoment || currentMoment ? moments : null}
        onViewCount={onViewCount}
        analyticsHandler={trackViews}
        analyticsCTAHandler={trackVideoCTAs}
        autoplay={autoplay || !!momentIdForAutoplay}
        onControlClick={trackPlayerControls}
        displayCaptionsButton={captions.length > 0}
        // @ts-ignore
        ctaTimestamps={videoItem?.video?.ctaTimestamps || ctaTimestamps}
        watermarkSrc={showLogo ? videoItem?.orgLogoUrl : ''}
        watermarkPosition={videoItem?.orgLogoPosition}
        isAllowedMuted={false}
        onReady={() => {
          if (momentIdForAutoplay) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const moment = moments.find(({ id }) => id === momentIdForAutoplay);
            if (moment) {
              seekTo(moment.startTimestamp);
              setSelectedMoment(moment);
            }
          }
        }}
        customVerticalPlayerWidth={videoItemAspectRation === '9:16' ? 'unset' : undefined}
        customVerticalPlayerHeight={videoItemAspectRation === '9:16' ? 'unset' : undefined}
        style={style}
        thumbnailUrl={hasPortraitMode ? videoThumbnailUrl : undefined}
        hasPortraitMode={hasPortraitMode}
      >
        {!hasPortraitMode && isOverlayVisible ? (
          <VideoJSThumbnailOverlay thumbnailUrl={thumbnailUrl} title={title} />
        ) : null}
        <VideoJSPlayerTitle
          className={CLIENT_CLASSNAMES.playerTitle}
          title={title}
          isDisabled={isControlsDisabled}
        />
        {socialIcons && configuration.showSocialIcons && (
          <VideoSocialIcons isVisible title={title} socialIcons={socialIcons} />
        )}
        {promotedObjects.length && promotedContentSettings?.promoteObjects ? (
          <ExternalContentSlider
            objects={promotedObjects}
            isPlaying={isPlaying}
            currentTime={currentTime}
            duration={duration}
            isEnded={isEnded}
            showAtStart={promotedContentSettings?.promoteAtStart}
            showAtEnd={promotedContentSettings?.promoteAtEnd}
            callToActionTitle={promotedContentSettings?.promotedObjectsLabel}
          />
        ) : null}
        {showEndCard ? renderEndCard : null}
      </VideoJSPlayer>
      {configuration.showThumbnailsSlider &&
      configuration.showMomentsBelowVideo &&
      videoItem.type === VideoItemType.Video &&
      moments.length > 0 ? (
        <VideoSlider
          items={moments}
          activeItems={selectedMoment ? [selectedMoment.id] : []}
          onSlideClick={handleMomentClick}
          stickyNavigationArrows
        />
      ) : null}
    </div>
  );
}

function normalizeVideoItemMoment(
  carouselMoment: Nullable<CarouselMoment>,
  videoItem: NormalizedVideoItem,
  videoFileUrl: string,
): VideoItemMoment | undefined {
  // @ts-ignore
  return carouselMoment
    ? {
        ...carouselMoment,
        duration: videoItem.videoDuration,
        type: VideoItemType.Moment,
        slug: videoItem.slug,
        videoFileUrl,
        // FIXME: remove `endCard` requirement from library
        endCard: undefined,
      }
    : undefined;
}
